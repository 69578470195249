import axios, { AxiosResponse } from "axios"
import { ApiResponse } from "../interfaces/ApiResponse";
import { ScannedFieldResponseData } from "./fieldsApi.service";

/**
 * A pagination interface
 * @interface
 */
interface Pagination {
    limit: number;
    lastPaginationKey?: string;
    lastEvaluatedKey?: LastEvaluatedKey;
    sortBy: string;
    sortOrder: string;
}

interface LastEvaluatedKey {
    CreatedDateTime: string;
    FormType?: string;
    TransactionID?: string;
}

/**
 * An interface describing search values
 * @interface
 */
export interface SearchScansParams {
    scanDateFrom?: string;
    scanDateTo?: string;
    scanField?: string;
    searchValue?: string;
    selectedTemplateType?: string;
    unresolvedIssues?: boolean;
    pagination?: Pagination;
    lastEvaluatedKey?: LastEvaluatedKey;
}

/**
 * An interface describing delete scan(s) values
 * @interface
 */
export interface DeleteScansParams {
    selectedTemplateType: string;
    transactionIds: string[];
}

/**
 * An interface describing params for scans report
 * @interface
 */
interface ScansReportParams {
    scanDateFrom: string;
    scanDateTo: string;
    period: string;
}

/**
 * An interface describing mapped response data from search scan request
 * @interface
 */
export interface SearchScansResponseData {
    id: string;
    scanId: string;
    location: string;
    scanDate: string;
    issues: number;
    formName: string;
    formId: number;
    formType: string;
    isSubmitted: boolean;
    isSetForManualReview: boolean;
    lastUpdatedDateTime: string;
    lastUpdatedDateTimeSec: number;
    submittedBy: string;
    editedBy: string;
    issueStatus: string;
    sourceFileObjectPath: string;
    formStatus: string;
    systemStatus: string;
}

/**
 * An interface describing last evaluated pagination key from search scan request.
 * Used as follow-up key for next page in pagination
 * @interface
 */
export interface SearchScansPaginationKey {
    TransactionID: string;
    FormType: string;
    CreatedDateTime: number;
}

/**
 * An extended ApiResponse interface with last pagination key
 * @interface
 */
export interface ScanSearchApiResponse extends ApiResponse {
    currentPageNumber?: number;
    lastPaginationKey: SearchScansPaginationKey;
    lastEvaluatedKey?: LastEvaluatedKey
}

/**
 * An extended ApiResponse interface with presigned URL and other information about scan
 * @interface
 */
export interface ScanByIdApiResponse extends ApiResponse {
    imagePresignedURL?: string;
    lastUpdatedDateTimeSec?: number;
    totalPage?: number;
    wasAlreadySubmitted?: boolean;
}

/**
 * An extended ApiResponse interface with full form presigned URL
 * @interface
 */
export interface ScanFullFormResponse extends ApiResponse {
    pdfPresignedURL?: string;
}

/**
 * An extended SearchSCansResponseData response with scanned fields
 * @interface
 */
export interface ScanWithFields extends SearchScansResponseData {
    fieldsList: ScannedFieldResponseData[];
}

/**
 * An API service for retrieving and updating scans
 * 
 * @module api/scansApi.service
 */
export default {
    /**
     * Collects a list of scans filtered by search values and paginated by pagination config
     * 
     * @param {SearchScansParams} body Body with search values and pagination config 
     * @returns {Promise<ScanSearchApiResponse>} A promise with results
     */
    getBySearch: async (body: SearchScansParams): Promise<ScanSearchApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/scans/search`;;
            let bodyWithFixedPagination = null;
            if(body.pagination?.lastPaginationKey === 'none') {
                bodyWithFixedPagination = {
                    ...body,
                    pagination: {
                        limit: body.pagination.limit
                    }
                }
            }

            const response = await axios.post(path, bodyWithFixedPagination || body, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return response.data;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: [],
                    lastPaginationKey: null as unknown as SearchScansPaginationKey,
                    message: error.message as string
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                lastPaginationKey: null as unknown as SearchScansPaginationKey,
                message: error as string
            };   
        }
    },
    /**
     * Fetch various information about selected scan, like datetime of last update, manual review or presigned URL of image
     * 
     * @param {string} formType Name of form type
     * @param {string} scanId Id of selected scan
     * @returns {Promise<ScanByIdApiResponse>} Various information about selected scan
     */
    getById: async (formType: string, scanId: string): Promise<ScanByIdApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/scans/byScanId/${formType}/${scanId}`;
            const response = await axios.get(path, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return response.data;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: [],
                    message: error.message as string
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                message: error as string
            };   
        }
    },
    /**
     * Save made changes in scan for later or submit scan for SAP
     * 
     * @param {Object} payload Object with properties of scan to updated or submitted 
     * @returns {ApiResponse} A general API response
     */
    postSave: async(payload: any): Promise<ApiResponse> => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/scan/save`;
            const response = await axios.post(path, payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return {
                ...response.data,
                status: response.status
            };
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: error?.response?.data?.validationresults || [],
                    message: (error?.response?.data?.message || error.message) as string,
                    status: error?.response?.status
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                message: error as string
            };   
        }
    },
    /**
     * Just a tester for availability of target in presigned URL
     * 
     * @param {string} presignedImageUrl A presigned of URL to test
     * @returns {AxiosResponse,ApiResponse} A general API response
     */
    testPresignedImage: async(presignedImageUrl: string): Promise<AxiosResponse | ApiResponse> => {
        try {
            const response = await axios.get(presignedImageUrl, {
                headers: {
                    'Content-Type': 'application/xml'
                }
            })
            return response;
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {
                    searchParams: {},
                    results: [],
                    message: error.message as string
                };    
            }
            
            return {
                searchParams: {},
                results: [],
                message: error as string
            };   
        }
    },
    // eslint-disable-next-line consistent-return
    postReport: async (body: ScansReportParams) => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/scans/report`;
            const response = await axios.post(path, body, {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: 'text',
                responseEncoding: 'base64'
            })
            const filename = response.headers['content-disposition'].replace('attachment;filename=', '');

            const hiddenDownloadLink = document.createElement('a');
            hiddenDownloadLink.href = `data:${response.headers['content-type']}';base64,${response.data}`;
            hiddenDownloadLink.download = filename;
            hiddenDownloadLink.click();
            hiddenDownloadLink.remove();            
        } catch(error) {
            if(axios.isAxiosError(error)) {
                return {};    
            }
            
            return {};   
        }
    },
    // delete scans service
    deleteScans: async (payload: DeleteScansParams) => {
        try {
            const path = `${process.env.REACT_APP_BASE_API_GATEWAY_URL}/scan/softDelete`;
            const response = await axios.post(path, payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return {
                ...response.data
            };
        } catch(error) {
            if(axios.isAxiosError(error)) {
                if (error.response?.data?.message) {
                    return {
                        message: error.response?.data?.message as string
                    }
                }
                return {
                    message: error.message as string
                };    
            }
            return {
                message: error as string
            }; 
        }
    },
}