export default {
    ARE_YOU_SURE_TITLE: 'Are you sure?',
    ACTION_REQUIRED_TITLE: 'Additional Action Required',
    OK_BUTTON_TITLE: 'Ok',
    CANCEL_BUTTON_TITLE: 'Cancel',
    SUBMIT_BUTTON_TITLE: 'Submit',
    DELETE_BUTTON_TITLE: 'Delete',
    SUBMIT_FORM_BUTTON_TITLE: 'Submit Form',
    SEND_BUTTON_TITLE: 'Send',
    ERROR_OCCURED_TITLE: 'Something went wrong',
    TIMEOUT_ERROR_TITLE: 'Timeout error',
    TIMEOUT_ERROR_DESC: 'Request timed out. Please try again!',
    ERROR_CONFLICT_OCCURED_SUBMIT_TITLE: 'Submission Conflict',
    ERROR_CONFLICT_OCCURED_SAVE_TITLE: 'Save Conflict',
    ERROR_OCCURED_HTTP_MESSAGE: 'Our server returned ":ERROR". Please, try again or contact administrators.',
    SELECT_FORM_TYPE_LABEL: 'Select Form Template',
    SELECT_FORM_TYPE_WARNING: 'Please, select a form template',
    SELECT_FORM_LABEL: 'Form Template (optional)',
    SELECT_SCAN_DATE_RANGE_LABEL: 'Select Date Range',
    SELECT_SCAN_DATE_RANGE_START_LABEL: 'Select Start of Scan Date Range',
    SELECT_SCAN_DATE_RANGE_END_LABEL: 'Select End of Scan Date Range',
    SELECT_SCAN_DATE_RANGE_WARNING: 'Please, select a scan date range',
    SELECT_SCAN_DATE_START_PLACEHOLDER: 'Start Date',
    SELECT_SCAN_DATE_END_PLACEHOLDER: 'End Date',
    SEARCH_VALUE_PLACEHOLDER: 'Search Value',
    UNRESOLVED_ISSUES_LABEL: 'Issues',
    FORM_STATUS_LABEL: 'Form Status',
    FIND_SCANS_BUTTON_LABEL: 'Find Scans',
    CLEAR_SEARCH_BUTTON_LABEL: 'Reset Search',
    SUBMIT_SCANS_BUTTON_TITLE: 'Submit Scan',
    SAVE_CHANGES_BUTTON_TITLE: 'Save Changes',
    LOAD_MORE_BUTTON_TITLE: 'Load More',
    FIND_SCANS_RESULTS__HEADER_SCAN_ID: 'ScanID',
    FIND_SCANS_RESULTS__HEADER_ORIG_LOCATION: 'File Name',
    FIND_SCANS_RESULTS__HEADER_SCAN_DATE: 'Scan Date',
    FIND_SCANS_RESULTS__HEADER_ISSUES: 'Issues',
    FIND_SCANS_RESULTS__HEADER_STATUS: 'Status',
    FIND_SCANS_RESULTS__HEADER_FORM_STATUS: 'Form Status',
    FIND_SCANS_RESULTS__HEADER_SYSTEM_STATUS: 'System Status',
    FIND_SCANS_RESULTS__HEADER_LAST_MODIFIED_DATE: 'Last Modified Date',
    FIND_SCANS_RESULTS_UNSUBMITTED_TEXT: 'Unsubmitted',
    FORM_FIELDS_TABLE__HEADER_KEY: 'Name',
    FORM_FIELDS_TABLE__HEADER_SCANNED_VALUE: 'Scanned Value',
    FORM_FIELDS_TABLE__HEADER_ACCEPTED_VALUE: 'Accepted Value',
    FORM_FIELDS_TABLE__HEADER_MATCH: 'Match',
    FORM_FIELDS_TABLE__HEADER_CONFIDENCE: 'Confidence',
    FORM_FIELDS_TABLE__HEADER_LOCK: '',
    FORM_FIELDS_TABLE__HEADER_UNREADABLE: 'Unreadable',
    FORM_FIELD_VALIDATION_ERROR_TITLE: 'Submission Failed',
    FORM_FIELD_VALIDATION_ERROR_MESSAGE: 'New accepted value failed to fulfill data type validation requirements. Please check, if entered value\'s type is',
    FORM_FIELD_VALIDATION_ERROR_SHORT_MESSAGE: 'Please check, if entered value\'s type is',
    FORM_FIELDS_ALREADY_SUBMITTED_FORM: 'This form was already submitted.',
    FORM_FIELDS_SUBMIT_BUTTON_DISABLED_TOOLTIP: 'Please, lock all values before submitting.',
    INCOMPLETE_FORM_BUTTON_DISABLED_TOOLTIP: 'Please, select a scan before sending incomplete form.',
    INCOMPLETE_FORM_POPCONFIRM_MESSAGE: 'Should send this form to SAP as incomplete?',
    SUBMIT_SCAN_POPCONFIRM_MESSAGE: 'Submit this scan as complete?',
    UNDEFINED_VALUE: '',
    FIND_SCANS_UNDEFINED_MODAL_TITLE: 'Confirm Submission',
    FIND_SCANS_UNDEFINED_MODAL_MESSAGE: 'Are you sure you want to submit this form? 1 or more required fields have been marked "Unreadable".',
    FIND_SCANS_ACC_ID_SKIP_VALIDATION_MODAL_MESSAGE: 'Are you sure you want to submit this form? Account # validation from SAP is not performed.',
    FIND_SCANS_FORM_SUBMIT_OK_TITLE: 'Success',
    FIND_SCANS_FORM_SUBMIT_INITIATE_TITLE: 'Submittal Initiated',
    FIND_SCANS_FORM_CHANGES_SAVE_TITLE: 'Changes Saved',
    FIND_SCANS_FORM_SUBMIT_OK_MESSAGE: 'Scan submittal initiated. Check scan status for confirmation.',
    FIND_SCANS_FORM_SAVE_LATER_OK_MESSAGE: 'Your changes have been successfully saved.',
    FIND_SCANS_NO_RESULTS_TEXT: 'Filter scans to display results.',
    FIND_SCANS_NO_ACTIVE_FLOW_TEXT: 'A form template and flow task have not yet been defined for this scanned form.',
    UNSAVED_CHANGES_MESSAGE: 'It looks like there are unsaved changes, which will be lost, if you leave this page.', 
    DEFINE_FORMS_HEADER_TITLE: 'Form Templates',
    DEFINE_FORMS_SELECT_TYPE_LABEL: 'Form Template',
    DEFINE_FORMS_SELECT_LABEL: 'Select Template Version',
    DEFINE_FORMS_SELECT_VERSION_LABEL: 'Select Template Version',
    DEFINE_FORMS_INPUT_LABEL: 'Template Version Name',
    DEFINE_FORMS_SUBMIT_TITLE: 'Submit Form',
    DEFINE_FORMS_NEW_TYPE_PLACEHOLDER: 'Please, type a new template name (max. 20 characters)',
    DEFINE_FORMS_NEW_TYPE_SUBMIT: 'Add Form Template',
    DEFINE_FORMS_FORM_SUBMIT_OK_TITLE: 'Success',
    DEFINE_FORMS_FORM_SUBMIT_OK_MINOR_MESSAGE: 'The selected template was updated.',
    DEFINE_FORMS_FORM_SUBMIT_OK_MAJOR_MESSAGE: 'The new template was created.',
    DEFINE_FORMS_SUBMIT_BUTTON_STATE_1: 'Update Template',
    DEFINE_FORMS_SUBMIT_BUTTON_STATE_2: 'Submit New Template',
    DEFINED_FIELDS_SUBMIT_TITLE: 'Submit Fields',
    DEFINED_FIELDS_NAME_HEADER: 'Name',
    DEFINED_FIELDS_TYPE_HEADER: 'Type',
    DEFINED_FIELDS_CONFIDENCE_HEADER: 'Confidence',
    DEFINED_FIELDS_REQUIRED_HEADER: 'Required',
    DEFINED_FIELDS_NOT_READABLE_HEADER: 'Not Readable',
    DEFINED_FORM_VALIDATION_ERROR_TITLE: 'Validation failed',
    NOT_UNIQUE_FORM_NAME_MESSAGE: 'The form name is not unique. Please, choose different form name.',    
    DEFINE_FORMS_RADIO_1_LABEL: 'Upload New Form',
    DEFINE_FORMS_RADIO_2_LABEL: 'Select Existing Template',
    DEFINE_FORMS_CONFIRM_UPDATE_TITLE: 'Confirm Update',
    DEFINE_FORMS_UPDATE_FLOW_TASK: 'Are you sure you want to update this form template? Submitting these changes will also update the configured task flow.',
    DEFINE_FORMS_NEW_FLOW_TASK: 'The new form template was successfully submitted. You will need to configure a new task flow for :FORMTYPE to activate this template.',
    DEFINE_FORMS_ADVISORY_TITLE: 'Advisory',
    DEFINE_FORMS_ADVISORY_ADD_TEXT: 'Are you sure you want to add this field? You will need to enter a unique form template name and submit as new template.',
    DEFINE_FORMS_ADVISORY_DELETE_TEXT: 'Are you sure you want to delete this field? You will need to enter a unique form template name and submit as new template.',
    DEFINE_FORMS_BB_MISSING_TEXT_TITLE: 'Action required', 
    DEFINE_FORMS_BB_MISSING_TEXT: 'Row(s) with name ":NAMES" are missing bounding box, because it was deleted recently. Please note, that it should be redrawn.',
    DEFINE_FORMS_BB_MISSING_TEXT_SHORT: 'Bounding box required. Please redraw bounding box or right-click on row to delete field.',
    DEFINE_FORMS_BB_CONFIRM_TEXT: 'Please confirm name of the field by pressing ENTER key on your keyboard or clicking on other rows.',
    DEFINE_FORMS_FORM_TYPE_IS_EMPTY_MESSAGE: 'Form Type cannot be empty.',
    DEFINE_FORMS_UPLOAD_PAGES_TEXT: 'Please select the page numbers to be scanned',
    FIND_SCANS_TITLE: 'Find Scans',
    FILTER_SCANS_TITLE: 'Filter Scans',
    SELECT_TYPE_LABEL: 'Select Form Template',
    TYPE_LABEL: 'Form Template',
    FLOW_TASKS_TITLE: 'Flow Tasks',
    FLOW_TASKS_SELECT_FORM_LABEL: 'Select Template Version',
    FLOW_TASKS_FORM_LABEL: 'Template Version',
    SELECT_OUTPUT_LOCATION_LABEL: 'Select Output Location',
    OUTPUT_LOCATION_LABEL: 'Output Location',
    SELECT_OUTPUT_FILE_LABEL: 'Select Output File',
    OUTPUT_FILE_LABEL: 'Output File',
    FORM_FIELDS_TABLE__HEADER_FLOW_NAME: 'Flow Name',
    FORM_FIELDS_TABLE__HEADER_FORM_TYPE: 'Form Type',
    FORM_FIELDS_TABLE__HEADER_FORM: 'Template Version',
    FLOW_TASK_SUCCESS_TOAST_MESSAGE: 'The flow task was successfully created.',
    FLOW_TASK_INACTIVATE_TOAST_MESSAGE: 'The flow task was successfully inactivated.',
    FLOW_TASK_DEACTIVATION_MODAL_TITLE: 'Confirm Deactivation',
    FLOW_TASK_DEACTIVATION_MODAL_MESSAGE: 'Are you sure you want to deactivate this flow? There will be no active task flow configured for ',
    DEACTIVATE_FLOW_BUTTON_TITLE: 'Deactivate Flow',
    FLOW_TASK_ALL_FOR_MANUAL_REVIEW_MODAL_TITLE: 'Confirm Deactivation',
    FLOW_TASK_ALL_FOR_MANUAL_REVIEW_OFF_MODAL_MESSAGE: 'Are you sure you want to switch off "Review All Scans"? Only forms with issues will be flagged for manual review.',
    FLOW_TASK_ALL_FOR_MANUAL_REVIEW_ON_MODAL_MESSAGE: 'Are you sure you want to switch on "Review All Scans"? All scanned forms will be flagged for manual review.',
    FLOW_TASK_ALL_FOR_MANUAL_REVIEW_MODAL_BUTTON_TITLE: 'Yes',
    FLOW_TASK_ALL_FOR_MANUAL_REVIEW_MODAL_CANCEL_BUTTON_TITLE: 'No',
    MATCH_FIELDS_TITLE: 'Match Fields',
    MATCH_FIELDS_DESCRIPTION: 'To match fields, select a field in the Output File table, then select a field in the Form Template table and click `Match Fields`. To un-match fields, select a matched pair and click `Un-match Fields`.',
    MATCH_FIELDS_TITLE_READONLY: 'Field Value Matches',
    MATCH_FIELDS_LEFT_COLUMN_TITLE: 'Output File Fields',
    MATCH_FIELDS_RIGHT_COLUMN_TITLE: 'Form Template Fields',
    MATCH_FIELDS_MATCH_BUTTON: 'Match Fields',
    MATCH_FIELDS_UNMATCH_BUTTON: 'Un-match Fields',
    DEFINE_FORMS_UPLOAD_BUTTON_LABEL: 'Upload Form',
    DEFINE_FORMS_UPLOAD_BUTTON: 'Upload',
    FLOW_TASK_REVIEW_ALL_SCANS: 'Review All Scans',
    FLOW_TASK_REVIEW_ALL_SCANS_DESCRIPTION: 'Flag all scanned forms for manual review, even if there are no issues.',
    FORM_NOT_SELECTED_MESSAGE: 'Upload new form or select existing form template to begin.',
    NEW_FORM_UPLOAD_MESSAGE: 'Upload new sample form to begin.',
    SELECT_FORM_MESSAGE: 'Select form template and version to begin.',
    FORM_TYPE_SELECT_INFO: 'This value must match corresponding PaperStream file name.',
    DEFINE_FORMS_SELECT_TEMPLATE_VERSION: 'Select Template Version',
    DEFINE_FORMS_NEW_TEMPLATE_VERSION: 'New Template Version Name',
    DISCARD_CHANGES_MODAL_TITLE: 'Discard Changes',
    DISCARD_CHANGES_MODAL_MESSAGE: 'Your changes will be lost if you decide to continue. Are you sure you want to proceed?',
    DISCARD_CHANGES_MODAL_OK_BUTTON: 'Discard Changes',
    SIGN_OUT_MODAL_TITLE: 'Sign Out',
    SIGN_OUT_MODAL_SAVED_CHANGES_MESSAGE: 'Are you sure you want to sign out?',
    SIGN_OUT_MODAL_UNSAVED_CHANGES_MESSAGE: 'Your changes will be lost if you decide to continue. Are you sure you want to sign out?',
    SIGN_OUT_MODAL_OK_BUTTON: 'Sign Out',
    FLOW_TASKS_EMPTY_TABLE_PLACEHOLDER: 'There are no flow tasks to display. Please create a new flow task',
    FLOW_TASKS_EMPTY_TEMPLATE_PLACEHOLDER: 'Select form type and form template to display',
    FLOW_TASK_IDLE_MESSAGE: "This flow task is 'Idle', as no scans have been processed in 60 days or more.",
    ACCESS_DENIED_MODAL_TITLE: 'Access Denied',
    ACCESS_DENIED_MODAL_MESSAGE: 'You don\'t have permission to access this application. Please contact an administrator to request access.',
    VIEW_ONLY_TEXT: 'View Only',
    UNDEFINED_FORM_TEMPLATE_LABEL: 'Undefined Form Template / Flow Task',
    ISSUE_STATUS: 'Issue Status',
    ISSUE_STATUS_OPTION_1_LABEL: 'Processed Succesfully',
    ISSUE_STATUS_OPTION_2_LABEL: 'Unresolved Issues',
    ISSUE_STATUS_OPTION_3_LABEL: 'Unsubmitted',
    FORM_STATUS_OPTIONS: ['Processing Error', 'Submission Error', 'Submission In-progress', 'Submitted Successfully', 'Unresolved', 'Unsubmitted'],
    FORM_STATUS_DEFAULT_SELECTED: ['Processing Error', 'Submission Error', 'Submission In-progress', 'Unresolved', 'Unsubmitted'],
    UNDEFINED_FORM_TEMPLATE_KEY: 'NoActiveFlowDefined',
    FIND_SCANS_REPORT_TITLE: 'Generate Report',
    FIND_SCANS_REPORT_STARTS_MESSAGE: 'Your scans report is in progress. Please wait.',
    FIND_SCANS_REPORT_DONE_MESSAGE: 'Your scans report was finished. The download will start in a moment.',
    FIND_SCANS_GENERATE_REPORT_DROPDOWN: 'Generate Report',
    FIND_SCANS_GENERATE_REPORT_30DAYS: 'Past 30 days',
    FIND_SCANS_GENERATE_REPORT_60DAYS: 'Past 60 days',
    IDENTIFIED_NOTIDENTIFIED_REQUIRED_TYPE: 'identifiednotidentified',
    ACCOUNT_ID_VALIDATION: 'Account # Validation',
    ACCOUNT_ID_VALIDATION_DESC: 'Account ID is validated successfully',
    FIND_SCANS_DELETE_FORMS: 'Delete Form(s)',
    FIND_SCANS_DELETE_FORMS_MESSAGE: 'been deleted successfully.',
  }